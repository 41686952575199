import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BannerSecondary from "../../components/banners/bannerSecondary"
import Why from "../../components/why"
import Benefits from "../../components/benefits"
import Reviews from "../../components/reviews"
import Treatments from "../../components/treatments"
import CallToAction from "../../components/callToAction"
import FeaturedFaq from "../../components/faqs/featuredFaq"
import {
  Section,
  Container,
  Flex,
  FlexMobileOpp,
} from "../../components/layoutComponents"
import { StaticImage } from "gatsby-plugin-image"
import Calander from "../../components/calander"

export default function MassageTherapyCalgary() {
  return (
    <Layout>
      <SEO
        title="Massage Therapy near Calgary, Sound Therapy, Relaxation Massage"
        description="Massage therapy services in Calgary combined with a unique sound therapy experience!"
      />
      <BannerSecondary
        title="massage & sound therapy services near calgary"
        description="Combining skillful massage technique with sound therapy to leave you feeling tension free, relaxed, and revitalized."
        to1="/"
        link1="home"
        to2="/treatments"
        link2="treatments"
        to3="/treatments/massage-therapy-calgary"
        link3="massage therapy calgary"
      />
      <Section>
        <Container className="spacing">
          <div className="center">
            <h1 className="title bold caps">
              Therapeutic relaxation Massage therapy calgary{" "}
            </h1>{" "}
            <p className="accent upper">
              in combination with sound vibration therapy
            </p>
          </div>
          <Flex>
            <div>
              <p>
                Want to experience the best massage therapy Calgary has to
                offer? Therapeutic massage relieves trigger points within muscle
                tissue, helps to breakdown adhesions from overuse or injury,
                reduces pain and returns the tissues to normal function.
                Specializing in relaxation massage Calgary, we help increase
                circulation of the lymphatic system and blood systems, which
                helps to remove metabolic waste from muscles. As is implied in
                the names, it also relaxes your mind and allows you to distress.
                The use of these two modalities helps each client to achieve
                healing, improved movement, improved circulation and a peaceful,
                relaxed body, mind and soul. This type of massage services
                Calgary is aimed at bringing the body, mind and soul out of
                “fight & flight” stress into “rest & digest” peacefulness.
              </p>
              <p>Benefits of therapeutic relaxation massage include:</p>
              <ul>
                <li>Improved immunity</li>
                <li>
                  Increased circulation to sore and tires muscles to improve
                  muscle function
                </li>
                <li>Restful sleep</li>
                <li>Decreased pain</li>
                <li>Decrease severity and frequency of tension headaches</li>
                <li>
                  Improved posture and counteraction of the imbalances from
                  sitting for hours at a computer
                </li>
              </ul>
              <p>
                During your session, you may feel peaceful and relaxed, and may
                fall asleep. Many of our Calgary SW massage clients report
                having a deep, restful sleep the night of their sessions.
              </p>
            </div>
            <StaticImage
              src="../../images/massage-therapy-calgary.jpg"
              alt="massage therapy calgary - rmt calgary"
              className="stretch"
            />
          </Flex>
        </Container>
      </Section>
      <Section>
        <Container className="spacing">
          <h2 className="title bold caps">massage south calgary</h2>
          <Flex>
            <p>
              Massage is like the old saying, “An apple a day keeps the doctor
              away.” Regular massage helps the body to relax and improve
              circulation, which brings fresh blood to muscles. This effect can
              help the body to naturally heal itself. Having a massage at least
              once a month helps to keep the muscles tuned and ready for
              activity, as well as relax the mind. It’s the perfect addition to
              any self-care regime; like a mini-vacation each month (especially
              considering our clinic is located on-site of Misty Ridge Retreat
              B&B!).
            </p>
            <p>
              Our massage therapy in Calgary services refer to a comprehensive
              health management strategy focusing on the application of various
              techniques to positively affect the soft tissues and joints of the
              body. Massage techniques most commonly include pressure and
              compression, kneading, frictioning, and mobilizing to improve the
              health and condition of the muscles, tendons, skin, fascia or
              connective tissue of the body.
            </p>
            <div>
              <p>
                We are located just South of Calgary, and to many small towns
                including:
              </p>
              <ul>
                <li>Foothills</li>
                <li>Diamond Valley</li>
                <li>Millarville</li>
                <li>Black Diamond</li>
                <li>Turney Valley</li>
                <li>Okotokes</li>
                <li>Bragg Creek</li>
                <li>Aldersyde</li>
                <li>and more!</li>
              </ul>
            </div>
          </Flex>
        </Container>
      </Section>
      <Section>
        <Container className="spacing">
          <h2 className="title caps bold">
            a unique sound vibration therapy experience
          </h2>
          <FlexMobileOpp>
            <StaticImage
              src="../../images/massage-and-sound-therapy-calgary.jpg"
              alt="calgary sound vibration therapy"
              className="stretch"
            />
            <div>
              <p>
                We also offer the added benefit of sound vibration therapy with
                every massage. Therapeutic massage is relaxing all on its own,
                but therapeutic relaxation massage Calgary WITH sound vibration
                therapy takes the experience to a much deeper and more relaxing
                state that takes you to new meditation heights. Of course, if
                you do not want to use the Sound Therapy during your massage
                session, please let me know and I will be able to turn the table
                off.
              </p>
              <ul>
                <li>Stress reduction</li>
                <li>Pain reduction</li>
                <li>Increased energy, concentration, and memory</li>
                <li>Improved immune response</li>
                <li>Increased sleep quality</li>
                <li>Relaxation</li>
                <li>The ability to achieve a deeper meditation</li>
              </ul>
            </div>
          </FlexMobileOpp>
          <Flex>
            <p>
              All things have a vibration, right down to the molecules inside
              cells. They all vibrate in balance with each other. When that
              perfect balance of vibration becomes unbalanced dis-ease occurs,
              causing pain, stress, low energy, anxiety, lack of concentration
              and a poor immune system. Our sound therapy Calgary can bring this
              perfect vibration back into balance. It brings a unique and deeper
              experience to a regular therapeutic massage. It’s like getting a
              massage from the inside out! Sound vibration reaches the areas
              that even a regular massage therapist Calgary is not able to (like
              brainwaves, internal organs and molecules).
            </p>
            <p>
              Our Calgary massage experts use a state of the art Sound Therapy
              table, also known as Vibracoustic Table. The table is equipped
              with four stereo speakers mounted to the underside of a massage
              table. This allows you to feel the music as you lay on the table.
              Each song is personally chosen for your needs. The frequencies
              within the song vibrate at different levels and in turn vibrate
              every cell in your body. This vibration brings your mind body and
              soul back to it's original perfect balance thus alleviating
              "dis-ease". During the session you may feel peaceful and relaxed,
              and may even fall asleep. After the session you will likely feel
              calm, energized and light. Clients have reported that the night of
              their session, “they experienced the best sleep they have had in
              years!”
            </p>
          </Flex>
        </Container>
      </Section>
      <Reviews />
      <CallToAction />
      <Why />
      <Benefits />
      <FeaturedFaq />
      <Treatments />
      <CallToAction />
      <Calander />
    </Layout>
  )
}
